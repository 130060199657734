import { About } from "../components/About";
import { Navigation } from "../components/Navigation";

export function AboutPage() {
  return (
    <div>
      <Navigation />

      <About />
    </div>
  );
}
