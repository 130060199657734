import { Button, message, Space, Spin, Table } from "antd";
import axios from "axios";
import React, { MouseEvent,useEffect, useState } from "react";

import config from "../config";

interface User {
  id: string;

  username: string;
  name: string;
  surname: string;
  email: string;

  status: string;
  enabled: boolean;

  created_at: string;
}

export function AdminUsers() {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/api/v1/users`);
        setUsers(response.data);
      } catch {
        message.error("Ошибка при загрузке пользователей");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleBlockUser = async (userId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    await axios.post(`${config.backendUrl}/api/v1/users/${userId}/block`);
    message.success("Пользователь заблокирован");
    window.location.reload();
  };

  const handleUnblockUser = async (userId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    await axios.post(`${config.backendUrl}/api/v1/users/${userId}/unblock`);
    message.warning("Пользователь разблокирован");
    window.location.reload();
  };

  const columns = [
    { title: "Логин", dataIndex: "username", key: "username" },
    {
      title: "Имя и Фамилия",
      key: "fullName",
      render: (_: unknown, record: User) => `${record.name} ${record.surname}`,
    },
    { title: "Почта", dataIndex: "email", key: "email" },
    {
      title: "Статус",
      key: "enabled",
      render: (_: unknown, record: User) =>
        record.enabled ? "Активный" : "Заблокированный",
    },
    { title: "Роль", dataIndex: "status", key: "status" },
    {
      title: "Дата и время создания",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Блокировка",
      key: "actions",
      render: (_: unknown, record: User) => (
        <Space>
          {record.enabled ? (
            <Button
              type="primary"
              danger
              onClick={(e: MouseEvent<HTMLElement>) =>
                handleBlockUser(record.id, e)
              }
            >
              Заблокировать
            </Button>
          ) : (
            <Button
              type="default"
              onClick={(e: MouseEvent<HTMLElement>) =>
                handleUnblockUser(record.id, e)
              }
            >
              Разблокировать
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <Spin size="large" style={{ display: "block", margin: "20px auto" }} />
      ) : (
        <Table dataSource={users} columns={columns} rowKey="id" />
      )}
    </div>
  );
}
