import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import config from "../config";
import { IGame } from "../models/game_model";
import { LoadingSpinner } from "./LoadingSpinner";

export function Games() {
  const [games, setGames] = useState<IGame[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get(
          `${config.backendUrl}/api/v1/games/verified`,
        );
        setGames(response.data);
        setLoading(false);
      } catch {
        setError("Ошибка при загрузке данных");
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (games.length === 0) {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Игры не найдены 😔{" "}
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            К сожалению, на данный момент нет игр, но мы работаем над этим.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        {/*<div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">*/}
        {/* <h2 className="text-xl font-bold text-gray-900 mb-8">Все игры</h2> */}

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {games.map((game: IGame) => (
            <Link key={game.id} to={`/g/${game.id}`} className="group">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  alt={game.short_description}
                  src={game.image_url}
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
              <h3 className="mt-4 text-sm text-gray-700 text-center">
                {game.name}
              </h3>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
