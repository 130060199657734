import { RcFile } from "antd/es/upload";

import { IGameUpload } from "../models/game_model";

export const getBase64 = (
  file: RcFile,
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const removeFields = (
  obj: IGameUpload,
  fields: (keyof IGameUpload)[],
): Partial<IGameUpload> => {
  const result = { ...obj };
  fields.forEach((field) => {
    delete result[field];
  });
  return result;
};
