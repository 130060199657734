import axios from "axios";

import config from "../config";

interface ErrorResponse {
  code: number;
  error_type: string;
  reason: string;
}

export const sign_up = async (
  username: string,
  name: string,
  surname: string,
  password: string,
) => {
  try {
    const response = await axios.post(
      `${config.backendUrl}/api/v1/auth/sign_up`,
      {
        username: username,
        name: name,
        surname: surname,
        password: password,
      },
    );
    return response.data;
  } catch (error: unknown) {
    let errorMessage = "Что-то пошло не так";

    if (axios.isAxiosError(error)) {
      const backendError = error.response?.data as ErrorResponse;
      if (backendError && backendError.reason) {
        errorMessage = backendError.reason;
      } else {
        errorMessage = error.response?.statusText || errorMessage;
      }
      throw new Error(errorMessage);
    } else if (error instanceof Error) {
      throw new Error("Ошибка запроса: " + error.message);
    } else {
      throw new Error("Неизвестная ошибка");
    }
  }
};

export const login = async (username: string, password: string) => {
  try {
    const response = await axios.post(
      `${config.backendUrl}/api/v1/auth/login`,
      {
        username: username,
        password: password,
      },
    );
    return response.data;
  } catch (error: unknown) {
    let errorMessage = "Что-то пошло не так";

    if (axios.isAxiosError(error)) {
      const backendError = error.response?.data as ErrorResponse;
      if (backendError && backendError.reason) {
        errorMessage = backendError.reason;
      } else {
        errorMessage = error.response?.statusText || errorMessage;
      }
      throw new Error(errorMessage);
    } else if (error instanceof Error) {
      throw new Error("Ошибка запроса: " + error.message);
    } else {
      throw new Error("Неизвестная ошибка");
    }
  }
};

export const getProfile = async (token: string) => {
  const response = await axios.get(`${config.backendUrl}/api/v1/auth/profile`, {
    headers: {
      Authorization: token,
    },
  });
  return response.data;
};

export const getMyProfile = async () => {
  const token = localStorage.getItem("token");

  if (!token) {
    console.error("Токен отсутствует! Пользователь не авторизован.");
  } else {
    return await getProfile(token);
  }
};
