import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "./AuthContext";

const Logout = forwardRef<HTMLAnchorElement>((props, ref) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    logout();
    navigate("/");
    window.location.reload();
  };

  return (
    <a
      href="/logout"
      ref={ref}
      onClick={handleLogout}
      className="block px-4 py-2 text-base text-gray-700"
    >
      Выйти
    </a>
  );
});

export default Logout;
