import { Button, message, Space, Spin, Table, Tag } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import config from "../config";
import { ReducedIGame } from "../models/game_model";

export function AdminGames() {
  const [games, setGames] = useState<ReducedIGame[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchGames = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.backendUrl}/api/v1/games/`);
      setGames(response.data);
    } catch {
      message.error("Ошибка при загрузке игр");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  const handleAcceptGame = async (gameId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      await axios.post(`${config.backendUrl}/api/v1/games/${gameId}/accept`);
      message.success("Игра принята");
      fetchGames();
    } catch {
      message.error("Ошибка при принятии игры");
    }
  };

  const handleRejectGame = async (gameId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      await axios.post(`${config.backendUrl}/api/v1/games/${gameId}/reject`);
      message.warning("Игра отклонена");
      fetchGames();
    } catch {
      message.error("Ошибка при отклонении игры");
    }
  };

  const getStatusTag = (status: string) => {
    switch (status) {
      case "cancelled":
        return <Tag color="red">Отклонено</Tag>;
      case "pending":
        return <Tag color="gold">Рассматривается</Tag>;
      case "verified":
        return <Tag color="green">Принято</Tag>;
      default:
        return <Tag color="default">Неизвестно</Tag>;
    }
  };

  const columns = [
    { title: "Название", dataIndex: "name", key: "name" },
    {
      title: "Короткое описание",
      dataIndex: "short_description",
      key: "short_description",
    },
    { title: "Тип игры", dataIndex: "game_type", key: "game_type" },
    { title: "Автор", dataIndex: "author_username", key: "author_username" },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status: string) => getStatusTag(status),
    },
    {
      title: "Принять / Отклонить",
      key: "actions",
      render: (_: unknown, record: ReducedIGame) => (
        <Space>
          <Button
            type="primary"
            onClick={(e) => handleAcceptGame(record.id, e)}
          >
            Принять
          </Button>
          <Button
            type="default"
            danger
            onClick={(e) => handleRejectGame(record.id, e)}
          >
            Отклонить
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ width: "100%", marginTop: "16px" }}>
      {loading ? (
        <Spin size="large" style={{ display: "block", margin: "20px auto" }} />
      ) : (
        <Table
          dataSource={games}
          columns={columns}
          rowKey="id"
          pagination={{ position: ["bottomCenter"] }}
          style={{ minHeight: "400px" }}
          onRow={(record) => ({
            onClick: () => navigate(`/g/${record.id}`),
          })}
        />
      )}
    </div>
  );
}
