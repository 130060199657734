import axios from "axios";
import { useEffect, useState } from "react";

import config from "../config";

const links = [
  { name: "Игры", href: "/" },
  { name: "Стать автором", href: "/upload" },
];

export function About() {
  const [stats, setStats] = useState<{ name: string; value: number }[]>([]);

  useEffect(() => {
    axios
      .get(`${config.backendUrl}/api/v1/games/verified`)
      .then((response) => {
        setStats([
          { name: "Математических игр", value: response.data.length },
          { name: "Лет проекту", value: "6" },
        ]);
      })
      .catch((error) => console.error("Ошибка загрузки данных:", error));
  }, []);

  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      <div
        aria-hidden="true"
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-8xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Добро пожаловать на сайт математических игр!
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Уроки математики и занятия математических кружков иногда удобно
            проводить в игровом формате. Этот сайт — попытка собрать в одном
            месте описания математических игр — как широко известных, так и
            недавно придуманных. Мы хотим помочь учителям математики с выбором
            нестандартного формата для уроков, кружков, математических
            праздников и т.д.
          </p>

          <p className="mt-6 text-lg leading-8 text-gray-300">
            Сайт находится в разработке, на стадии добавления новых функций.
            Также мы планируем в ближайшее время указать источники и авторов игр
            там, где это возможно. Пишите, если вы знаете, каких авторов или
            источники стоит добавить. Автор проекта ни в коем случае не хочет
            присвоить себе авторство игр, придуманных другими! У каждой игры
            есть много вариаций, а также постоянно придумываются новые игры.
            Можно менять количество задач, число людей в команде, способ
            подсчёта очков и так далее. Сайт в большой степени отражает
            субъективный опыт и предпочтения автора проекта. Мы оставляем за
            собой право выбирать для публикации на этом сайте определённый
            вариант правил игры или вообще не публиковать правила каких-то игр
            без объяснения причин. В то же время призываем посетителей сайта
            творчески относиться к предложенным правилам, смело меняя их под
            свою учебную ситуацию и под своё настроение.
          </p>

          <p className="mt-6 text-lg leading-8 text-gray-300">
            Желаем удачных игровых занятий!
          </p>
        </div>

        <div className="mx-auto max-w-8xl lg:mx-0">
          <p className="mt-6 text-sm leading-8 text-gray-300">
            Автор проекта - Наталия Стрелкова, учитель математики школы №179 г.
            Москвы. Все вопросы по содержанию сайта можно присылать на адрес
            nstrelk@gmail.com <br />
            Текущий разработик - Август, технические вопросы присылайте на адрес
            august.mathactics@gmail.com <br />
            Идея проекта была предложена в 2017 году Александром Владимировичем
            Бегунцом. <br />
            Сайт был написан Антоном Ройтерштейном и его друзьями в 2018–2019
            годах в качестве школьного проекта, предложенного Антону
            А.В.Бегунцом. С 2021 года сайт работал нестабильно, а в 2024 и
            2025-ом годах начался новый этап развития.
          </p>
        </div>

        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
            {links.map((link) => (
              <a key={link.name} href={link.href}>
                {link.name} <span aria-hidden="true">&rarr;</span>
              </a>
            ))}
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.name} className="flex flex-col-reverse">
                <dt className="text-base leading-7 text-gray-300">
                  {stat.name}
                </dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
