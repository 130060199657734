import {
  HomeOutlined,
  PlayCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getMyProfile } from "../clients/auth_client";
import { AdminGames } from "../components/AdminGames";
import { AdminUsers } from "../components/AdminUsers";
import { useAuth } from "../components/AuthContext";
import { LoadingSpinner } from "../components/LoadingSpinner";

const { Content, Sider } = Layout;

export function AdminPanel() {
  const [selectedMenu, setSelectedMenu] = useState("games");
  const { isAuthenticated, isLoading } = useAuth();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMyUser = async () => {
      try {
        const myUser = await getMyProfile();
        console.log(myUser);
        if (myUser.status !== "admin") {
          navigate("/");
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Ошибка при получении профиля:", error);
        navigate("/");
      }
    };

    if (isLoading) return; // Ждем завершения проверки авторизации

    if (!isAuthenticated) {
      navigate("/");
    } else {
      fetchMyUser();
    }
  }, [isAuthenticated, isLoading, navigate]);

  if (isLoading || loading) {
    return <LoadingSpinner />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible className="h-screen flex flex-col justify-between">
        <div
          className="h-16 text-white text-center flex items-center justify-center text-lg bg-gray-900 cursor-pointer"
          onClick={() => window.location.reload()}
        >
          Админка
        </div>

        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedMenu]}
          onClick={(e) => {
            if (e.key === "home") {
              navigate("/");
            } else {
              setSelectedMenu(e.key);
            }
          }}
          style={{ flexGrow: 1 }}
        >
          <Menu.Item key="home" icon={<HomeOutlined />}>
            На главную
          </Menu.Item>
          <Menu.Item key="games" icon={<PlayCircleOutlined />}>
            Игры
          </Menu.Item>
          <Menu.Item key="users" icon={<UserOutlined />}>
            Пользователи
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout>
        <Content
          style={{
            margin: "16px",
            padding: "24px",
            background: "#fff",
            minHeight: "calc(100vh - 112px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {selectedMenu === "games" && <AdminGames />}
          {selectedMenu === "users" && <AdminUsers />}
        </Content>
      </Layout>
    </Layout>
  );
}
