import { Footer } from "../components/Footer";
import { Navigation } from "../components/Navigation";
import { UploadGame } from "../components/Upload";

export function UploadPage() {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow">
        <Navigation />

        <UploadGame />
      </div>

      <Footer />
    </div>
  );
}
