import { Tabs } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import config from "../config";
import { IGame } from "../models/game_model";
import { GameNotFoundPage } from "../pages/GameNotFoundPage";
import { LoadingSpinner } from "./LoadingSpinner";

export function Game() {
  const { id } = useParams<{ id: string }>();
  const [game, setGame] = useState<IGame | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchGame = async () => {
      try {
        const response = await axios.get(
          `${config.backendUrl}/api/v1/games/${id}`,
        );
        setGame(response.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error(
            `Ошибка запроса: ${error.response?.status} - ${error.response?.statusText}`,
          );
          console.error("Детали ошибки:", error.response?.data);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchGame();
  }, [id]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!game) {
    return <GameNotFoundPage />;
  }

  const gameDetails = [
    { label: "Тип игры", value: game.game_type },
    {
      label: "Время проведения",
      value: `От ${game.min_duration} до ${game.max_duration} минут`,
    },
    { label: "Сложность проведения", value: game.difficulty },
    { label: "Минимальное число ведущих", value: game.min_judges },
    {
      label: "Число команд",
      value: `От ${game.min_teams} до ${game.max_teams}`,
    },
    {
      label: "Число участников в команде",
      value: `От ${game.min_participants} до ${game.max_participants}`,
    },
  ];

  return (
    <div className="bg-white px-4 py-8 mx-auto max-w-7xl">
      <div className="flex space-x-8">
        <div className="flex-shrink-0">
          <img
            src={game.image_url}
            alt={game.short_description}
            className="w-48 h-48 rounded-lg object-cover"
          />
        </div>
        <div className="flex flex-col justify-between">
          <div>
            <h2 className="text-3xl font-bold">{game.name}</h2>
            <p className="mt-2 text-gray-600">{game.short_description}</p>
          </div>
          <div className="mt-4">
            <p className="text-gray-500">
              Пользователь добавивиший игру:{" "}
              <span className="font-medium">{game.author_username}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="mt-8 border-t border-gray-200 pt-6">
        <h3 className="text-lg font-medium text-gray-900">Общая информация</h3>
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-6 gap-x-6">
          {gameDetails.map((detail, index) => (
            <div key={index}>
              <h4 className="text-sm font-medium text-gray-500">
                {detail.label}
              </h4>
              <p className="mt-1 text-lg text-gray-900">{detail.value}</p>
            </div>
          ))}
        </div>
      </div>

      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: "Описание",
            key: "1",
            children: game.description,
            disabled: game.description === null,
            // disabled: true,
          },
          {
            label: "Правила",
            key: "2",
            children: game.rules,
            disabled: game.rules === null,
          },
          {
            label: "Требования",
            key: "3",
            children: game.requirements,
            disabled: game.requirements === null,
          },
        ]}
      />
    </div>
  );
}
